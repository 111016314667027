// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section-4 {
  margin-top: 100px;
}
@media screen and (min-width: 834px) {
  .section-4 .title {
    text-align: center;
  }
}

.buttons {
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  cursor: pointer;
  max-width: 250px;
  margin-right: auto;
  margin-left: auto;
}
@media screen and (min-width: 834px) {
  .buttons {
    flex-direction: row;
    justify-content: space-around;
    max-width: none;
  }
}
.buttons a {
  margin-bottom: 3rem;
  border: 1px solid white;
  border-radius: 17px;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-decoration: none;
  display: flex;
  justify-content: center;
}
@media screen and (min-width: 834px) {
  .buttons a {
    justify-content: start;
  }
}
.buttons a .mail-label {
  font-size: 14px;
  color: white;
  font-weight: 700;
  margin-top: auto;
  margin-bottom: auto;
}
@media screen and (min-width: 834px) {
  .buttons a .mail-label {
    font-size: 16px;
  }
}
@media screen and (min-width: 1440px) {
  .buttons a .mail-label {
    font-size: 20px;
  }
}
.buttons a img {
  width: 2rem;
  margin-right: 0.5rem;
}`, "",{"version":3,"sources":["webpack://./src/styles/contact.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;AACI;EADF;IAEI,kBAAA;EAEJ;AACF;;AACA;EACE,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,eAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,iBAAA;AAEF;AADE;EATF;IAUI,mBAAA;IACA,6BAAA;IACA,eAAA;EAIF;AACF;AAFE;EACE,mBAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;EACA,mBAAA;EACA,iBAAA;EACA,oBAAA;EACA,qBAAA;EACA,aAAA;EACA,uBAAA;AAIJ;AAHI;EAXF;IAYI,sBAAA;EAMJ;AACF;AALI;EACE,eAAA;EACA,YAAA;EACA,gBAAA;EACA,gBAAA;EACA,mBAAA;AAON;AANM;EANF;IAOI,eAAA;EASN;AACF;AARM;EATF;IAUI,eAAA;EAWN;AACF;AATI;EACE,WAAA;EACA,oBAAA;AAWN","sourcesContent":[".section-4{\r\n  margin-top:100px;\r\n  .title{\r\n    @media screen and (min-width: 834px){\r\n      text-align: center;\r\n    }\r\n  }\r\n}\r\n.buttons{\r\n  margin-top:5rem;\r\n  display: flex;\r\n  flex-direction: column;\r\n  flex-wrap: wrap;\r\n  cursor: pointer;\r\n  max-width: 250px;\r\n  margin-right: auto;\r\n  margin-left: auto;\r\n  @media screen and (min-width: 834px){\r\n    flex-direction: row;\r\n    justify-content: space-around;\r\n    max-width: none;\r\n  }\r\n\r\n  a{\r\n    margin-bottom: 3rem;\r\n    border: 1px solid white;\r\n    border-radius: 17px;\r\n    padding-left: 2rem;\r\n    padding-right: 2rem;\r\n    padding-top: 1rem;\r\n    padding-bottom: 1rem;\r\n    text-decoration: none;\r\n    display: flex;\r\n    justify-content: center;\r\n    @media screen and (min-width: 834px){\r\n      justify-content: start;\r\n    }\r\n    .mail-label{\r\n      font-size: 14px;\r\n      color: white;\r\n      font-weight: 700;\r\n      margin-top:auto;\r\n      margin-bottom: auto;\r\n      @media screen and (min-width: 834px){\r\n        font-size: 16px;\r\n      }\r\n      @media screen and (min-width: 1440px){\r\n        font-size: 20px;\r\n      }\r\n    }\r\n    img{\r\n      width: 2rem;\r\n      margin-right: 0.5rem;\r\n    }\r\n    \r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iframe {
  width: 100%;
  height: 206px;
  max-width: 456px;
}
@media screen and (min-width: 834px) {
  .iframe {
    height: 228px;
  }
}
@media screen and (min-width: 1440px) {
  .iframe {
    height: 416px;
    width: 745px;
  }
}

.iframe-container {
  display: flex;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
}
@media screen and (min-width: 992px) {
  .iframe-container {
    padding-left: 150px;
    padding-right: 150px;
    justify-content: start;
  }
}
@media screen and (min-width: 1440px) {
  .iframe-container {
    padding-left: 220px;
    padding-right: 220px;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/product.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,gBAAA;AACF;AACE;EALF;IAMI,aAAA;EAEF;AACF;AADE;EARF;IASI,aAAA;IACA,YAAA;EAIF;AACF;;AADA;EACE,aAAA;EACA,uBAAA;EACA,kBAAA;EACA,mBAAA;AAIF;AAHE;EALF;IAMI,mBAAA;IACA,oBAAA;IACA,sBAAA;EAMF;AACF;AALE;EAVF;IAWI,mBAAA;IACA,oBAAA;EAQF;AACF","sourcesContent":[".iframe{\r\n  width: 100%;\r\n  height: 206px;\r\n  max-width: 456px;\r\n\r\n  @media screen and (min-width: 834px){\r\n    height: 228px;\r\n  }\r\n  @media screen and (min-width: 1440px){\r\n    height: 416px;\r\n    width: 745px;\r\n  }\r\n\r\n}\r\n.iframe-container{\r\n  display: flex;\r\n  justify-content: center;\r\n  padding-left: 20px;\r\n  padding-right: 20px;\r\n  @media screen and (min-width: 992px){\r\n    padding-left: 150px;\r\n    padding-right: 150px;\r\n    justify-content: start;\r\n  }\r\n  @media screen and (min-width: 1440px){\r\n    padding-left: 220px;\r\n    padding-right: 220px;\r\n\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

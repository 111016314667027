// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.price-ticker {
    font-size: 18px;
    color: #007bff;
    margin: 20px 0;
    padding: 10px;
    background-color: #f4f4f4;
    border: 1px solid #ddd;
    border-radius: 4px;
    text-align: center;
  }
  
  .price-ticker .error {
    color: red;
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/PriceTicker.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,cAAc;IACd,cAAc;IACd,aAAa;IACb,yBAAyB;IACzB,sBAAsB;IACtB,kBAAkB;IAClB,kBAAkB;EACpB;;EAEA;IACE,UAAU;EACZ","sourcesContent":[".price-ticker {\n    font-size: 18px;\n    color: #007bff;\n    margin: 20px 0;\n    padding: 10px;\n    background-color: #f4f4f4;\n    border: 1px solid #ddd;\n    border-radius: 4px;\n    text-align: center;\n  }\n  \n  .price-ticker .error {\n    color: red;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
